import { memo } from "react";

import { NextLink } from "Components/Lib/Next";
import { Button, Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { localesText } from "Utils/LocalesText.Util";

const ImageComponent = () => {
  const logos = [
    { src: "Assets/Images/Others/home_deal_girl.png", alt: "Disney" },
    { src: "Assets/Images/Others/home_deal_amr.png", alt: "Facebook" },
    { src: "Assets/Images/Others/home_deal_tyro.png", alt: "Airbnb" },
    { src: "Assets/Images/Others/home_deal_aftershock.png", alt: "Apple" },
    { src: "Assets/Images/Others/home_deal_launtel.png", alt: "Spark" },
  ];

  return (
    <div className="relative w-full bg-clever-white-slate rounded-[20px] md:rounded-[40px] p-8 md:p-[60px]">
      <div className="inline-flex w-full flex-nowrap overflow-hidden">
        <ul className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-8">
          <li className="flex items-end gap-1">
            <img src={logos[0].src} alt={logos[0].alt} />

            <img src={logos[1].src} alt={logos[1].alt} />
          </li>

          <li>
            <img src={logos[2].src} alt={logos[2].alt} />
          </li>

          <li>
            <img src={logos[3].src} alt={logos[3].alt} />
          </li>

          <li>
            <img src={logos[4].src} alt={logos[4].alt} />
          </li>
        </ul>

        <ul
          className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-8"
          aria-hidden="true"
        >
          <li className="flex items-end gap-1">
            <img src={logos[0].src} alt={logos[0].alt} />

            <img src={logos[1].src} alt={logos[1].alt} />
          </li>

          <li>
            <img src={logos[2].src} alt={logos[2].alt} />
          </li>

          <li>
            <img src={logos[3].src} alt={logos[3].alt} />
          </li>

          <li>
            <img src={logos[4].src} alt={logos[4].alt} />
          </li>
        </ul>
      </div>

      <div className="flex flex-col items-center mt-6">
        <Typography.Body className="text-[#878F9D] md:absolute">
          {localesText("home.download_our_app")}
        </Typography.Body>

        <NextLink href={PATHS_NAME.SHOPPERS} className="absolute -bottom-6">
          <Button className="rounded-full bg-clever-link px-7">
            {localesText("home.show_deals")}
          </Button>
        </NextLink>
      </div>
    </div>
  );
};

export default memo(ImageComponent);
