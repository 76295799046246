import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => (
  <div className="flex flex-1 flex-col pb-10 pt-16 text-center">
    <div className="mb-6">
      <Typography.SectionTitle>
        <RotateHighlightText content={localesText("common.pay")} />{" "}
        {localesText("for_shoppers.and_get_paid")}
      </Typography.SectionTitle>
    </div>

    <Typography.Body className="text-clever-description sm:pr-4">
      {localesText("for_shoppers.just_one_click_away")}
    </Typography.Body>
  </div>
);

export default memo(TitleComponent);
