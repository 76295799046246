import { memo, useEffect, useRef, useState } from "react";

import { useIsInViewport } from "Hooks/useIsInViewPort";
import { NextLink } from "Components/Lib/Next";
import { RotateHighlightText, Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { HomeProps } from "Types";
import { localesText } from "Utils/LocalesText.Util";
import { formatCurrency } from "Utils/Number.Util";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";

const TitleComponent = ({
  referralBonus,
}: {
  referralBonus: HomeProps["referralBonus"];
}) => {
  const scrollRef = useRef<any>(null);
  const isInViewport = useIsInViewport(scrollRef);

  const [firstScroll, setFirstScroll] = useState(true);

  useEffect(() => {
    if (isInViewport && firstScroll) {
      setFirstScroll(false);
      logAnalyticEvent({ key: TRACKING_EVENTS.referralViewed });
    }
  }, [firstScroll, isInViewport]);

  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-10 text-center md:text-left">
        <Typography.SectionTitle>
          {localesText("for_shoppers.refer_friends_to")}
        </Typography.SectionTitle>
        <Typography.SectionTitle>
          <RotateHighlightText
            content={localesText("common.earn_money", {
              amount: formatCurrency(referralBonus),
            })}
          />
        </Typography.SectionTitle>
      </div>

      <Typography.Body className="mb-6 px-2 text-center text-clever-description sm:pr-4 md:px-0 md:pr-11 md:text-left">
        {localesText("for_shoppers.the_more_the_merrier", {
          amount: formatCurrency(referralBonus),
        })}
      </Typography.Body>

      <Typography.Body className="px-2 text-center text-clever-description sm:pr-4 md:px-0 md:pr-11 md:text-left">
        <strong className="text-clever-link underline">
          <NextLink href={PATHS_NAME.TERMS}>
            {localesText("common.terms_and_conditions")}
          </NextLink>
        </strong>{" "}
        {localesText("common.apply")}.
      </Typography.Body>
    </div>
  );
};

export default memo(TitleComponent);
