import { SectionWrap } from "Components/Layouts";
import { localesText } from "Utils/LocalesText.Util";
import { useExpand } from "Hooks/useExpand";

import Ecommerce from "Assets/Lotties/industries_e-commerce.json";
import Telco from "Assets/Lotties/industries_telco.json";
import Biller from "Assets/Lotties/industries_biller.json";
import Gaming from "Assets/Lotties/industries_gaming_and_crypto.json";
import SMB from "Assets/Lotties/industries_smb.json";

import ImageComponent from "./Image.Component";
import Collapses from "./Collapses";

export const INDUSTRIES = [
  {
    id: 1,
    title: localesText("common.e_commerce"),
    content: localesText("merchant_api.e_commerce_description"),
    lottieData: Ecommerce,
    href: "e_commerce",
  },
  {
    id: 2,
    title: localesText("merchant_api.telco"),
    content: localesText("merchant_api.telco_description"),
    lottieData: Telco,
    href: "telco",
  },
  {
    id: 3,
    title: localesText("merchant_api.biller"),
    content: localesText("merchant_api.biller_description"),
    lottieData: Biller,
    href: "billers",
  },
  {
    id: 4,
    title: localesText("merchant_api.marketplace_and_fintech"),
    content: localesText("merchant_api.marketplace_and_fintech_description"),
    lottieData: Gaming,
    href: "marketplace_and_fintech",
  },
  {
    id: 5,
    title: localesText("merchant_api.sme"),
    content: localesText("merchant_api.sme_description"),
    lottieData: SMB,
    href: "smbs",
  },
];

const Section2 = () => {
  const { expandingId, onToggle } = useExpand();

  return (
    <SectionWrap
      containerProps={{
        className: "flex justify-center py-16 md:py-24 mt-[180px]",
      }}
      className="flex justify-center"
      contentContainerProps={{
        className:
          "flex justify-center items-center gap-10 w-full flex-col lg:flex-row",
      }}
    >
      <Collapses
        expandingId={expandingId}
        onToggle={onToggle}
        data={INDUSTRIES}
      />
      <ImageComponent id={expandingId} />
    </SectionWrap>
  );
};

export default Section2;
