import { memo, useEffect, useRef } from "react";

import ConfettiLeft from "Assets/Svg/confetti_left.svg";
import ConfettiRight from "Assets/Svg/confetti_right.svg";
import Image from "Components/Lib/Next/Image";
import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import confetti from "canvas-confetti";

const TitleComponent = () => {
  const duration = 5 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
  const componentRef = useRef(null);

  const randomInRange = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const handleConfetti = () => {
    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 100 * (timeLeft / duration);
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        }),
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        }),
      );
    }, 250);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleConfetti();
          observer.disconnect();
        }
      },
      { threshold: 0.5 },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={componentRef}
      className="flex flex-col lg:flex-row gap-10 lg:gap-6 w-screen relative min-h-[344px]"
    >
      <Image
        src={ConfettiLeft}
        alt="confetti-left"
        className="object-cover absolute z-0 bottom-20 left-0"
      />

      <Typography.SectionTitle className="text-clever-gray text-center z-10 mx-4 sm:mx-16 min-[1600px]:mx-[120px] md:leading-[72px]">
        {localesText("home.whether_you_are_shopping")}

        <span className="font-semibold ml-3 text-clever-black">
          {localesText("home.earn_cash_back")}
        </span>

        <img
          src="Assets/Svg/home_lightning_green.svg"
          alt="lightning"
          className="mx-1 inline"
          width={86}
          height={86}
        />

        <span className="mr-2">-</span>

        <span className="inline-block">{localesText("home.no_waiting")}</span>

        <span className="font-semibold text-clever-black ml-2 inline">
          {localesText("home.get_paid")}
        </span>
      </Typography.SectionTitle>

      <Image
        src={ConfettiRight}
        alt="confetti-right"
        className="object-cover absolute right-0 top-14"
      />
    </div>
  );
};

export default memo(TitleComponent);
